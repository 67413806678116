.hidden{
    display: none;
}

.modal{
    width: 600px;
    height: 500px;
    position: fixed;
    border-radius: 30px;
    background-color: #efdecd;
    border: 25px solid rgb(97 176 192);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    padding: 50px;
}

.modal h1{
    color: rgb(38 22 23);
    font-size: 30px;
}

.modal p{
    font-size: 20px;
    padding: 30px;
    font-family: 'Oswald', sans-serif;
    color: rgb(38, 22, 23);
}

.modal li{
    font-size: 20px;
    margin: 10px; 
    font-family: 'Oswald', sans-serif;
    padding: 5px 30px 15px 30px;
    color: rgb(38, 22, 23);
}

.overlay{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(3px);
    z-index: 10;
}

#reset, #demo{
    width: 100px;
    height: 40px;
    cursor: pointer;
    font-weight: 600;
    font-size: 20px;
    font-family: 'Oswald', sans-serif;
    border-radius: 20px;
    position: absolute;
    bottom: 30px;
    border: 2px outset rgb(97 176 192);
}

#reset{
    background-color: rgb(97 176 192);
    right: 30px;
}

#demo{
    background-color: rgb(97 176 192);
    right: 150px;
}

.modal a{
    position: relative;
    left: 10px;
    top: 100px;
    margin: 10px;
    font-family: 'Oswald', sans-serif;
    font-size: 23px;
}

#reset:hover, #demo:hover{
    border: 2px solid rgb(97 176 192);
}

.btn-close{
    cursor: pointer;
    font-size: 32px;
    color: rgb(38 22 23);
    background-color: transparent;
    position: absolute;
    right: 20px;
    top: 5px;
    border: none;
    font-family: 'Oswald', sans-serif;
}

audio{
    position: absolute;
    left: 50%;
    margin-top: 20px;
    transform: translate(-50% , 0%);
    color: #efdecd;
}