#leaderboard-container{
    padding-bottom: 100px;
}

.leaderboard-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #000;
}

