@import './styles/reset.scss';
@import './styles/clickable-object.scss';
@import './styles/shop-content.scss';
@import './styles/modal.scss';
@import './styles/leaderboard.scss';

body{
    background-color: #efdecd;
}
h1 {
    font-family: 'Luckiest Guy';
    font-size: 40px;
    color: black;
}

h2 {
    font-family: 'Luckiest Guy';
    font-size: 40px;
    color: rgb(38 22 23);
}

p {
    font-family: 'Luckiest Guy';
    font-size: 8px;
    color: rgb(38 22 23);
    margin: 5px;
} 

.main-page {
    background-color: rgb(183 142 114);
    border: 2px outset rgb(85 146 141);
    border-radius: 10px;
    position: relative;
}

#background {
    background-color: rgb(97 176 192);
    box-shadow: 0px 5px 20px 0px rgb(36 62 59);
    border: 10px outset rgb(85 146 141);
    width: 1000px;
    height: 600px;
    border-radius: 8px;
    margin: 80px auto 0px auto;
}

#header-container{
    display: flex;
}

#main-container {
    display: flex;
}

#main-header {
    height: 80px;
    flex: 1;
    border-radius: 8px;
    margin: 15px 15px 5px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

#main-total {
    padding: 15px 0px 0px 30px;
    user-select: none;
}

#main-title-container {
    height: 200px;
    width: 215px;
    position: absolute;
    margin-top: -50px;
    margin-left: -80px;
    left: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 5;
    user-select: none;
}

#main-title-container img {
    height: 290px;
    width: auto;
    rotate: 13deg;
}

#main-menu {
    padding: 15px 30px 0px 0px;
    user-select: none;
}
#main-shop {
    width: 220px;
    height: 465px;
    margin: 10px 20px 20px 10px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: start;
}

#tnt img{
    image-rendering: pixelated;
}

#canvas-container {
    position: relative;
    margin: 10px 5px 20px 20px;
}

#canvas2{
    position: absolute;
    top: 0;
    left: 0;
    margin-top: 2px;
    margin-left: 2px;  
}

.btn {
    cursor: pointer;
}

#timer-container {
    width: 250px;
    height: 50px;
    background-color: rgb(183 142 114);
    border: 2px outset rgb(85 146 141);
    border-radius: 10px;
    margin: 20px auto;
    font-size: 25px;
    color: rgb(38 22 23);
    font-family: 'Luckiest Guy';
    text-align: center;
    line-height: 50px;
}