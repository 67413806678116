#shop-title{
    width: 100%;
    height: 20px;
    margin: 20px 0px 0px 68px;
    user-select: none;
    font-size: 35px;
    text-decoration: underline;
}

.shop-item {
    width: 50px;
    height: 50px;
    background-color: rgb(97 176 192);
    border: 2px solid rgb(85, 146, 141);
    margin: 5px;
    border-radius: 4px;
    user-select: none;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
}

#main-stats{
    width: 180px;
    height: 120px;
    margin: 5px;
    background-color: rgb(97 176 192);
    border: 3px solid rgb(85 146 141);
    border-radius: 4px;
    padding: 5px;
}

#main-stats h2 {
    text-decoration: underline; 
    font-size: 20px; 
    margin: 5px 0px 5px 20px;
}
#main-stats p{
    font-size: 14px;
    padding: 2px;
}

.shop-item:hover{
    border: 2px outset rgb(36 62 59);
}

.shop-item p {
    font-family: 'Luckiest Guy';
    font-size: 15px;
    color: rgb(38 22 23);
    margin-top: 5px;

}

.tooltip::before{
    content: attr(data-tooltip);
    position: absolute;
    bottom: 100%;
    padding: 7px 10px;
    margin: 5px;
    font-family: "Oswald";
    white-space: nowrap; /* prevents the tooltip text from wrapping */
    background-color: rgb(36 62 59);
    color: #efdecd;
    border-radius: 7.5px;
    z-index: 1;
    opacity: 0;
    transition: opacity 0.05s;
}

.tooltip:hover::before{
    opacity: 0.9;
}

.sold-out::after{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    content: "Purchased";
    color: white;
    font-size: 10px;
    font-family: "Luckiest Guy";
    position: absolute;
    background-color: black;
    opacity: 0.5;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}